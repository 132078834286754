export const environment = {
    NAME: 'stage',
    APP_NAME: 'nucleus',
    APP_MODE: 'default',
    APP_NAMESPACE: 'n',
    APP_REGION: 'eu',
    APP_VERSION: 26,
    BASE_HREF: '/d',
    LOG_LEVEL: 'debug',
    APP_URL: 'https://app-eu-stage3.creately.com',
    APP_US_URL: 'https://app-stage3.creately.com',
    APP_EU_URL: 'https://app-eu-stage3.creately.com',
    APP_AU_URL: 'https://app-au-stage3.creately.com',
    APP_UAE_URL: 'https://app-uae-stage3.creately.com',
    SITE_URL: 'https://stage3.creately.com/',
    API_BASE_URL: 'wss://neutrino-eu-stage3.creately.com/neutrino/stream',
    REST_API_BASE_URL: 'https://neutrino-eu-stage3.creately.com/neutrino/service',
    FILE_API_BASE_URL: 'https://neutrino-eu-stage3.creately.com/neutrino/files',
    MESSAGE_PROTOCOL_VERSION: 1,
    BASE_URL: 'https://app-eu-stage3.creately.com/d',
    RESOURCE_LOCATION: 'https://resources-eu-stage3.creately.com/',
    SHAPE_RESOURCE_BASE: 'shape/def/',
    LIBRARY_RESOURCE_BASE: 'shape/lib/',
    GRAVITY_LIBRARY_URL: 'https://auth-eu-stage3.creately.com/js/gravity-3.2.4.js',
    USER_IMAGE_BASE_URL : 'https://creately-user-profile-eu-stage3.s3.eu-west-1.amazonaws.com/',
    SENTRY_ENDPOINT: 'https://54f05c2d49264e9092de8bd49d90ea53@sentry.io/1221305',
    DIAGRAM_SVG_BASE_URL: 'https://neutrino-diagram-svg-eu-stage3.s3.eu-west-1.amazonaws.com/',
    CUSTOM_IMAGE_BASE_URL: 'https://neutrino-custom-image-eu-stage3.s3.eu-west-1.amazonaws.com/',
    ZENDESK_CONTACT_US_URL: false,
    COGNITO_LOGOUT_URL: 'https://createlyeustage3.auth.eu-west-1.amazoncognito.com/logout',
    COGNITO_CLIENT_ID: '3fjkga10jhv57rgptuneq5dc00',
    COGNITO_LOGOUT_REDIRECT_URL: 'https://stage3.creately.com',
    AMPLITUDE_TRACKER: 'ca864083374a7309516b88ed1c94a937',
    INDICATIVE_TRACKER: '34e1b954-e772-4ae9-9d78-d861a30853e9',
    CREATELY_ANALYTICS: false,
    CREATELY_ANALYTICSJS_URL: 'https://creately.com/static/js/creately-analytics-2.7.2.js',
    CREATELY_ANALYTICS_SCHEMA: 'iglu:com.creately/creately_app_events/jsonschema/1-0-0',
    GA_TRACKER: false,
    GA4_MEASUREMENT_ID: 'G-CCDVMNN28K',
    GA_TAG_MANAGER: 'GTM-5RF68JT',
    EVENT_TRACKER_CONFIG: [
        { name: 'amplitude', allow: [
                'n.conversion.dialog.upgrade.closeOnOverlay.click',
                'n.conversion.dialog.upgrade.load',
                'n.conversion.dialog.upgrade.close',
                'n.conversion.dialog.upgrade.cancel',
                'n.conversion.dialog.plans.load',
                'n.conversion.dialog.warning.ok.click',
                'n.conversion.dialog.plans.click',
                'n.conversion.dialog.upgrade.viewOptions',
                'n.conversion.demo.banner.click',
                'n.n.notification.conversion.promo.load',
                'n.conversion.payment-failed.banner.click',
                'n.n.notification.conversion.promo.click',
                'n.conversion.dialog.warning.showplan.click',
                'n.dialog.teamInvite.',
                'n.header.demo.signup.click',
                'n.header.right.upgrade.click',
                'n.conversion.tag.premium.click',
                'n.dialog.share.email.invite.click',
                'n.header.right.share.click',
             ],
        },
        { name: 'crisp' },
        { name: 'snowplow' },
        { name: 'indicative', allow: [
            'n.conversion.dialog.upgrade.closeOnOverlay.click',
            'n.conversion.dialog.upgrade.load',
            'n.conversion.dialog.upgrade.close',
            'n.conversion.dialog.upgrade.cancel',
            'n.conversion.dialog.plans.load',
            'n.conversion.dialog.warning.ok.click',
            'n.conversion.dialog.plans.click',
            'n.conversion.dialog.upgrade.viewOptions',
            'n.conversion.demo.banner.click',
            'n.n.notification.conversion.promo.load',
            'n.conversion.payment-failed.banner.click',
            'n.n.notification.conversion.promo.click',
            'n.conversion.dialog.warning.showplan.click',
            'n.dialog.teamInvite.',
            'n.header.demo.signup.click',
            'n.header.right.upgrade.click',
            'n.conversion.tag.premium.click',
            'n.dialog.share.email.invite.click',
            'n.header.right.share.click',
        ]},
        {
            name: 'sendinblue',
            allow: [
                'n.conversion.',
                'n.dialog.share.',
                'n.route.document.create.load',
                'n.creator.item',
                'n.creator.templates.click' ],
        },
        {
            name: 'ga4',
            allow: [
                'n.canvas.shape.plusCreate.click',
                'n.conversion.dialog.upgrade.load',
                'n.dialog.demo.load',
                'n.header.right.export.click',
                'n.header.right.share.click',
                'n.left.dashboard.createDiagram.click',
                'n.left.folder.createDocument.click',
                'n.left.library.shape.drop',
                'n.left.templates.item.click',
                'n.load' ],
        },
        { name: 'hyperdx' },
    ],
    TEMPLATE_CONTEXT_API_URL: 'https://oai-eu-stage3.creately.com/api/oai/generate-context',
    VIZ_SHAPE_TEMPLATES: {
        DIAGRAM_ID: '4DEpSVmzosr',
        VERTICAL: {
            DEFAULT: 'HPxuNxHt2wf',
            GROUP: '4dKO8r69zxS',
            KANBAN: 'HPxuNxHt2wf',
        },
        HORIZONTAL: {
            DEFAULT: 'LE4LdhDxbOK',
        },
        VERTICAL_HORIZONTAL: {
            DEFAULT: '3XlnhF2NoEX',
        },
    },
    VIZ_REQUEST_ID_URL: 'https://oai-eu-stage3.creately.com/api/viz/generate',
    PLAN_PERM_ERROR_REDIRECT_URL: 'https://stage3.creately.com/plans/',
    PLAN_PAGE_URL: 'https://stage3.creately.com/plans/',
    PERSONAL_PURCHSAE_URL: 'https://stage3.creately.com/upgrade/personal/?period=y',
    TEAM_PURCHSAE_URL: 'https://stage3.creately.com/upgrade/team/?period=y&users=3',
    DIAGRAM_COMMUNITY_URL: 'https://stage3.creately.com/community-templates',
    TEAM_PORTAL_URL: 'https://account-eu-stage3.creately.com',
    DATADOG_COLLECTOR_TOKEN: false,
    PDF_EXPORT_URL: 'https://tools-eu-stage3.creately.com/generate_pdf',
    SOURCE_CONFIG: {
        gdrive: {
            shareUrl: 'https://api-eu-stage3.creately.com/api/source/gdrive/share',
        },
    },
    DISABLE_WEBSOCKET_CONNECTION: false,
    GOOGLE_CUSTOM_SEARCH: {
        id: '012660921508659707786:l4gtwqs1yc7',
        url: 'https://www.googleapis.com/customsearch/v1',
    },
    FETCH_DATA_URL: 'https://api-eu-stage3.creately.com/api/util/fetch',
    TIPTAP_WS_URL: 'wss://tiptap-eu-stage3.creately.com',
    PHOTON_URL: {
        HOST_NAME: 'photon-eu-stage3.creately.com',
        PORT: '443',
    },
    PLUGIN_AXION_MANIFEST: 'https://internal-stage.creately.dev/axion/manifest.json',
    SVG_TO_RASTER_URL: 'https://tools-eu-stage3.creately.com/generate_image',
    EMBED_ENDPOINT_URL: 'https://embed-eu-stage3.creately.com',
    GRAPHQL_API_BASE_URL: 'https://api-eu-stage3.creately.com/api/v0',
    GOOGLE_ACCESS_TOKEN_URL: 'https://api-eu-stage3.creately.com/api/token/google',
    ZENDESK_KEY: false,
    ZENDESK_HELP_CENTER_URL: false,
    ABOUT_ERROR_REPORT_URL: 'https://support.creately.com/hc/en-us/articles/360002102335-The-Creately-Error-Report/',
    TEMPORARY_EMAIL_DOMAINS: [ '@temp.creately.com' ],
    SENDINBLUE_CLIENT_KEY: 'r0pd53ia0v7s4rakwxpmqysh',
    GOOGLE_PICKER: {
        clientId: '',
        developerKey: '',
        appId: '',
    },
    PARTNERSTACK_COUPON_CODES: 'PARTNERSTACK, ENT-SP-DS-160',
    NANGO_API_COMMANDS_URL: 'https://pipe-eu-stage3.creately.com/integration/cpaas/command',
    NANGO_API_HOST: 'https://ipaas-stage3.creately.com',
    NANGO_PUBLIC_KEY: '8e4bd2dd-1109-43b1-96ed-aabae4f404d5',
    WISTIA_PLAYER: 'https://fast.wistia.com/embed/medias/',
    CREATELY_SEARCH_BASE_URL: 'https://search-eu-stage3.creately.com/v1',
    CREATELY_ENTITY_SEARCH_URL: 'https://search-eu-stage4.creately.com/v1/search',
    TOOLTIP_STATIC_BASE_URL: 'https://tooltip-eu-stage3.creately.com/',
    EXTERNAL_DIAGRAM_CONVERT_URL: 'https://tools-eu-stage3.creately.com/external/diagram/converter',
    TYPESENSE_API: 'https://ts-eu-stage3.creately.com',
    TYPESENSE_API_KEY_URL: 'https://api-eu-stage3.creately.com/api/integration/typesense/search-key',
    CRISP_CHAT: 'e8a1c3f1-a1ea-455c-a6c2-1dfe022f84a7',
    MY_ASKAI_ID: 'Lz7fZ2Qi3l861KbuoUkfkRIEzCMJ2B',
    HYPERDX_TOKEN: '91ab7fca-4311-4651-8ee9-a12510caf292',
    HYPERDX_API_URL: 'https://otel-logs-hdx-stage.creately.com',
    COOKIE_DOMAIN: '.creately.com',
    IMAGE_PROXY : 'https://tools-stage.creately.com/image_proxy/proxy?url=',
};
